import { useEffect, useState } from "react";
import "./styles/Player.css";
import playBtn from './assets/play-btn.svg'
// import pauseBtn from './assets/pause-btn.svg'
import playBtnBlack from './assets/play-button-black.svg'
import pauseBtnBlack from './assets/pause-black.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
// import DownloadLink from "react-download-link";

function Player({ targetClicked, setTargetClickedUrl, targetClickedName, setTargetClickedName, songsItems, setSongsItems,playStop, playing, setPlaying, currentSong, setSong, currentUrl, setUrl }) {

  let player = document.getElementById('player')

  const [ currentVolume, setCurrentVolume] = useState('1')
  const [ currentDuration, setCurrentDuration] = useState(0)

  // useEffect(() => {
  //   console.log(`playing : ${playing}`);
  // })

  useEffect(() => {
    document.title = currentSong;
  })

  useEffect(() => {
    rangeTime()
  })

  // useEffect(() => {
  //   console.log(currentDuration);
  // })

  useEffect(() => {
    console.log(currentUrl);
    // console.log(currentSong);
    for (let element of document.querySelectorAll('li')) {
      if (element.getAttribute('data') == currentUrl) {
        element.style.color = 'white';
      }
    }
  }, [])

  function playSong() {
    document.getElementById('player').play();
    setPlaying(true)
    setInterval(rangeTime(), 1000); // problème ici
  }

  function pauseSong() {
    document.getElementById('player').pause();
    setPlaying(false)
    clearInterval(rangeTime()); // problème ici
  }
  function manageSong(e) {
    let target = document.getElementsByTagName('li')
    if (playing) {
      document.getElementById('player').pause();
      setPlaying(false)
      e.target.setAttribute('src', playBtnBlack)
      for (let element of target) {
        if (element.getAttribute('data') == currentUrl ) {
          element.firstChild.setAttribute('src', playBtn)
        }
      }
    } else {
      document.getElementById('player').play();
      setPlaying(true)
      e.target.setAttribute('src', pauseBtnBlack)
      for (let element of target) {
        if (element.getAttribute('data') == currentUrl ) {
          element.firstChild.setAttribute('src', 'https://e-cdns-files.dzcdn.net/cache/slash/images/common/animated/equalizer/12x12/inverse.1ed354e01b9e97207f6ac710e6c23eaa.gif')
        }
      }
    }
  }

  function change_time() {
    console.log(document.getElementById('rangeTime').value);
    document.getElementById('player').currentTime = document.getElementById('rangeTime').value;
    setCurrentDuration(document.getElementById('rangeTime').value)
  }

  function change_vol(e) {
    document.getElementById('player').volume = document.getElementById("change_vol").value;
    // document.getElementById("change_vol").value = e.target.value
    setCurrentVolume(e.target.value)
  }

  function rangeTime() {
    let rangeElement = document.getElementById('rangeTime');
    document.getElementById('rangeTime').setAttribute('max', document.getElementById('player').duration)
    document.getElementById('rangeTime').setAttribute('value', document.getElementById('player').currentTime)
    setCurrentDuration(document.getElementById('player').currentTime)
  }
  setTimeout(function () {
    setInterval(rangeTime(), 1000)
  }, 1500);

  function playNextSong(currentUrl) {
    let ul = document.getElementById("myUL");
    let li = ul.getElementsByTagName('li');
    for (let i = 0; i < li.length; i++) {
      if (li[i].getAttribute('data') == currentUrl) {
        if (li[i].nextSibling == null) { // TODO: get the first element
        } else {
          li[i].style.color = "#4ed1f7"
          li[i].firstChild.setAttribute('src', playBtn)
          li[i].nextSibling.style.color = "white"
          li[i].nextSibling.firstChild.setAttribute('src', 'https://e-cdns-files.dzcdn.net/cache/slash/images/common/animated/equalizer/12x12/inverse.1ed354e01b9e97207f6ac710e6c23eaa.gif')
          let nextSong = li[i].nextSibling.getAttribute('data')
          setUrl(nextSong)
        }
      }
    }
  }

  function downloadFile() {
    // e.preventDefault();
    let aLink = document.createElement('a');
    aLink.setAttribute('download', 'true')
    aLink.setAttribute('href', currentUrl)
    // document.body.appendChild(aLink)
    aLink.click()
  }

  return (
    <div className="audioBox">
      <div className="audioWrap">
        <input type='image' id='playerImg' onClick={manageSong} src={playBtnBlack} />
        <div className='title'>
          <p className="songTitle">
            {currentSong.split('.').shift().split('-').pop().replace('_', ' ').replace('_', ' ').replace('_', ' ').replace('_', ' ').replace('_', ' ')}
          </p>
          <p style={{display: 'none', color: 'black'}}>
            {Math.round(currentDuration)}
            /

          </p>
          <div className="controls">
            <div style={{display: ''}}>
              <input style={{padding: '0'}} type="range" id="rangeTime" onChange={change_time} step="1" min="0" value={currentDuration}/>
            </div>
            <div style={{display: 'none'}}>
              <input type='range' id="change_vol" onChange={change_vol} step="0.05" min="0" max="1" value={currentVolume}/>
            </div>
            <audio id="player"
              src={currentUrl}
              autoPlay={playing}
              onPause={() => console.log('pause')}
              onEnded={() => playNextSong(currentUrl)}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
