import { useEffect } from 'react'
import "./styles/SongList.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faDownload } from '@fortawesome/free-solid-svg-icons'
import playBtn from './assets/play-btn.svg'
// import pauseBtn from './assets/pause-btn.svg'
// import pauseBtn from 'https://e-cdns-files.dzcdn.net/cache/slash/images/common/animated/equalizer/12x12/inverse.1ed354e01b9e97207f6ac710e6c23eaa.gif'
import playBtnBlack from './assets/play-button-black.svg'
import pauseBtnBlack from './assets/pause-black.svg'

function SongList({ songsItems, setSongsItems, targetClickedUrl, setTargetClickedUrl, targetClickedName, setTargetClickedName, playStop, playing, setPlaying, currentSong, setSong, currentUrl, setUrl }) {


    useEffect(() => {
      setSong(currentUrl.split('/').pop());
    })

  return (
    <div>
      <ul id="myUL">
        {songsItems.songs.map((index) => (
          <li
            key={`${index.id}-${index.title}`}
            name={`${index.title}`}
            data={`${index.url}`}
            onClick={(e) => {
              e.preventDefault();
              let target = document.getElementsByTagName('li')
              for (var element of target) {
                element.style.color = "#4ed1f7"
                element.firstChild.setAttribute('src', playBtn)
                document.getElementById('playerImg').setAttribute('src', playBtnBlack);
              }
              if (currentUrl === e.target.getAttribute('data')) {
                if (playing) {
                  document.getElementById('player').pause();
                  e.target.firstChild.setAttribute('src', playBtn)
                  e.target.style.color = "white"
                  document.getElementById('playerImg').setAttribute('src', playBtnBlack);
                  setPlaying(false)
                } else {
                  document.getElementById('player').play();
                  e.target.firstChild.setAttribute('src', 'https://e-cdns-files.dzcdn.net/cache/slash/images/common/animated/equalizer/12x12/inverse.1ed354e01b9e97207f6ac710e6c23eaa.gif')
                  e.target.style.color = "white"
                  document.getElementById('playerImg').setAttribute('src', pauseBtnBlack)
                  setPlaying(true)
                }
              } else {
                setUrl(e.target.getAttribute('data'))
                setTimeout(function () {
                  document.getElementById('player').play();
                }, 100); // ?????????
                document.title = e.target.getAttribute('name')
                e.target.firstChild.setAttribute('src', 'https://e-cdns-files.dzcdn.net/cache/slash/images/common/animated/equalizer/12x12/inverse.1ed354e01b9e97207f6ac710e6c23eaa.gif')
                e.target.style.color = "white"
                document.getElementById('playerImg').setAttribute('src', pauseBtnBlack)
                setPlaying(true)
              }
            }}
          >
            <img src={playBtn} alt={`Lecture: ${index.title}`} />
              {index.title.toLowerCase()}
              <span>{index.date.split('T').shift()}</span>
            </li>
        ))}
      </ul>
    </div>
  );
}

export default SongList;
