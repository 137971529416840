import './styles/Search.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// const element = <FontAwesomeIcon icon={faCoffee} />

function Search({ rescueData, targetClicked, setTargetClickedUrl, targetClickedName, setTargetClickedName, songsItems, setSongsItems, playStop, playing, setPlaying, currentSong, setSong, currentUrl, setUrl }) {

  function handleSearch() {
    // Declare variables
    let input, filter, ul, li, i, txtValue;
    input = document.getElementById('searchedSong');
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName('li');
    let closeArrow = document.getElementById('closeArrow');

    if (input.value.length > 0) {
      console.log(input.value.length);
      closeArrow.style.display = "inherit"
    } else {
      closeArrow.style.display = "none"
    }

    // Loop through all list items, and hide those who don't match the search query
     for (i = 0; i < li.length; i++) {
       txtValue = li[i].textContent || li[i].innerText;
       if (txtValue.toUpperCase().indexOf(filter) > -1) {
         li[i].style.display = "";
       } else {
         li[i].style.display = "none";
       }
     }
   }

   function resetSearch() {
     document.getElementById('searchedSong').value = ""
     handleSearch();
   }

  return(
    <div className='search'>
    <form onSubmit={(e) => e.preventDefault()}>
      <FontAwesomeIcon icon={faSearch} color='grey' />
      <input
          id='searchedSong'
          type="text"
          onChange={handleSearch}
          style={{paddingLeft: 30}}
          autoComplete='off'
      />
      <div id="closeArrow">
        <FontAwesomeIcon icon={faTimes} color='grey' onClick={resetSearch}/>
      </div>
      <div id='result'></div>
      </form>
    </div>
  )
}

export default Search
