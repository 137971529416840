// url: 'https://sessions.thetuco.fr/static/media'

export const songs = [
  {
    id: '13_06_2023-DES_ENVIES_D_ALCOOL.MP3-47',
    date: '13/06/2023',
    title: 'DES ENVIES D ALCOOL',
    url: 'https://sessions.thetuco.fr/static/media/13_06_2023-DES_ENVIES_D_ALCOOL.MP3'
  },
  {
    id: '13_06_2023-DEVIL_IN_THE_FLESH.MP3-615',
    date: '13/06/2023',
    title: 'DEVIL IN THE FLESH',
    url: 'https://sessions.thetuco.fr/static/media/13_06_2023-DEVIL_IN_THE_FLESH.MP3'
  },
  {
    id: '06_06_2023-ANDREAS_BLUES.MP3-781',
    date: '06/06/2023',
    title: 'ANDREAS BLUES',
    url: 'https://sessions.thetuco.fr/static/media/06_06_2023-ANDREAS_BLUES.MP3'
  },
  {
    id: '06_06_2023-JUMPING_JACK_FLASH.MP3-840',
    date: '06/06/2023',
    title: 'JUMPING JACK FLASH',
    url: 'https://sessions.thetuco.fr/static/media/06_06_2023-JUMPING_JACK_FLASH.MP3'
  },
  {
    id: '06_06_2023-NO_MERCY_NO_DICE.MP3-495',
    date: '06/06/2023',
    title: 'NO MERCY NO DICE',
    url: 'https://sessions.thetuco.fr/static/media/06_06_2023-NO_MERCY_NO_DICE.MP3'
  },
  {
    id: '06_06_2023-ROSES_N_GUNS.MP3-56',
    date: '06/06/2023',
    title: 'ROSES N GUNS',
    url: 'https://sessions.thetuco.fr/static/media/06_06_2023-ROSES_N_GUNS.MP3'
  },
  {
    id: '31_05_2023-LIFE_IS_A_LONG_RAOD.MP3-942',
    date: '31/05/2023',
    title: 'LIFE IS A LONG RAOD',
    url: 'https://sessions.thetuco.fr/static/media/31_05_2023-LIFE_IS_A_LONG_RAOD.MP3'
  },
  {
    id: '31_05_2023-MY_ONLY_MISTAKE.MP3-938',
    date: '31/05/2023',
    title: 'MY ONLY MISTAKE',
    url: 'https://sessions.thetuco.fr/static/media/31_05_2023-MY_ONLY_MISTAKE.MP3'
  },
  {
    id: '31_05_2023-ONE_OF_THESE_DAYS.MP3-124',
    date: '31/05/2023',
    title: 'ONE OF THESE DAYS',
    url: 'https://sessions.thetuco.fr/static/media/31_05_2023-ONE_OF_THESE_DAYS.MP3'
  },
  {
    id: '23_05_2023-PRISONER.MP3-450',
    date: '23/05/2023',
    title: 'PRISONER',
    url: 'https://sessions.thetuco.fr/static/media/23_05_2023-PRISONER.MP3'
  },
  {
    id: '23_05_2023-WHAT_A_JOURNEY.MP3-973',
    date: '23/05/2023',
    title: 'WHAT A JOURNEY',
    url: 'https://sessions.thetuco.fr/static/media/23_05_2023-WHAT_A_JOURNEY.MP3'
  },
  {
    id: '28_02_2023-MAQUILLA_V1.MP3-522',
    date: '28/02/2023',
    title: 'MAQUILLA V1',
    url: 'https://sessions.thetuco.fr/static/media/28_02_2023-MAQUILLA_V1.MP3'
  },
  {
    id: '28_02_2023-MAQUILLA_V2.MP3-665',
    date: '28/02/2023',
    title: 'MAQUILLA V2',
    url: 'https://sessions.thetuco.fr/static/media/28_02_2023-MAQUILLA_V2.MP3'
  },
  {
    id: '07_02_2023-MAQUILLA.MP3-70',
    date: '07/02/2023',
    title: 'MAQUILLA',
    url: 'https://sessions.thetuco.fr/static/media/07_02_2023-MAQUILLA.MP3'
  },
  {
    id: '11_10_2022-IDEE_11_10_2022.MP3-974',
    date: '11/10/2022',
    title: 'IDEE 11 10 2022',
    url: 'https://sessions.thetuco.fr/static/media/11_10_2022-IDEE_11_10_2022.MP3'
  },
  {
    id: '11_10_2022-SHOOK_ME_ALL_NIGHT_LONG.MP3-972',
    date: '11/10/2022',
    title: 'SHOOK ME ALL NIGHT LONG',
    url: 'https://sessions.thetuco.fr/static/media/11_10_2022-SHOOK_ME_ALL_NIGHT_LONG.MP3'
  },
  {
    id: '27_09_2022-IDEE1_27_09_2022.MP3-921',
    date: '27/09/2022',
    title: 'IDEE1 27 09 2022',
    url: 'https://sessions.thetuco.fr/static/media/27_09_2022-IDEE1_27_09_2022.MP3'
  },
  {
    id: '27_09_2022-IDEE2_27_09_2022.MP3-497',
    date: '27/09/2022',
    title: 'IDEE2 27 09 2022',
    url: 'https://sessions.thetuco.fr/static/media/27_09_2022-IDEE2_27_09_2022.MP3'
  },
  {
    id: '23_02_2022-IDEE.MP3-307',
    date: '23/02/2022',
    title: 'IDEE',
    url: 'https://sessions.thetuco.fr/static/media/23_02_2022-IDEE.MP3'
  },
  {
    id: '15_12_2021-LOVERMAN_REMIX.MP3-598',
    date: '15/12/2021',
    title: 'LOVERMAN REMIX',
    url: 'https://sessions.thetuco.fr/static/media/15_12_2021-LOVERMAN_REMIX.MP3'
  },
  {
    id: '24_11_2021-LOVERMAN_REMIX.MP3-40',
    date: '24/11/2021',
    title: 'LOVERMAN REMIX',
    url: 'https://sessions.thetuco.fr/static/media/24_11_2021-LOVERMAN_REMIX.MP3'
  },
  {
    id: '10_11_2021-LOVERMAN_TONE_V1.MP3-122',
    date: '10/11/2021',
    title: 'LOVERMAN TONE V1',
    url: 'https://sessions.thetuco.fr/static/media/10_11_2021-LOVERMAN_TONE_V1.MP3'
  },
  {
    id: '10_11_2021-LOVERMAN_TONE_V2.MP3-875',
    date: '10/11/2021',
    title: 'LOVERMAN TONE V2',
    url: 'https://sessions.thetuco.fr/static/media/10_11_2021-LOVERMAN_TONE_V2.MP3'
  },
  {
    id: '10_11_2021-MY_ONLY_MISTAKE_REMIX.MP3-988',
    date: '10/11/2021',
    title: 'MY ONLY MISTAKE REMIX',
    url: 'https://sessions.thetuco.fr/static/media/10_11_2021-MY_ONLY_MISTAKE_REMIX.MP3'
  },
  {
    id: '04_11_2021-ONE_OF_THESE_DAYS_REMIX.MP3-766',
    date: '04/11/2021',
    title: 'ONE OF THESE DAYS REMIX',
    url: 'https://sessions.thetuco.fr/static/media/04_11_2021-ONE_OF_THESE_DAYS_REMIX.MP3'
  },
  {
    id: '26_10_2021-ONE_OF_THESE_DAYS_INSTRU.MP3-594',
    date: '26/10/2021',
    title: 'ONE OF THESE DAYS INSTRU',
    url: 'https://sessions.thetuco.fr/static/media/26_10_2021-ONE_OF_THESE_DAYS_INSTRU.MP3'
  },
  {
    id: '26_10_2021-ONE_OF_THESE_DAYS_INSTRU_REMIX.MP3-519',
    date: '26/10/2021',
    title: 'ONE OF THESE DAYS INSTRU_REMIX',
    url: 'https://sessions.thetuco.fr/static/media/26_10_2021-ONE_OF_THESE_DAYS_INSTRU_REMIX.MP3'
  },
  {
    id: '26_10_2021-ONE_OF_THESE_DAYS_REMIX.MP3-543',
    date: '26/10/2021',
    title: 'ONE OF THESE DAYS REMIX',
    url: 'https://sessions.thetuco.fr/static/media/26_10_2021-ONE_OF_THESE_DAYS_REMIX.MP3'
  },
  {
    id: '26_10_2021-ONE_OF_THESE_DAYS_V1.MP3-130',
    date: '26/10/2021',
    title: 'ONE OF THESE DAYS V1',
    url: 'https://sessions.thetuco.fr/static/media/26_10_2021-ONE_OF_THESE_DAYS_V1.MP3'
  },
  {
    id: '26_10_2021-ONE_OF_THESE_DAYS_V2.MP3-926',
    date: '26/10/2021',
    title: 'ONE OF THESE DAYS V2',
    url: 'https://sessions.thetuco.fr/static/media/26_10_2021-ONE_OF_THESE_DAYS_V2.MP3'
  },
  {
    id: '13_10_2021-DEVIL_IN_THE_FLESH.MP3-290',
    date: '13/10/2021',
    title: 'DEVIL IN THE FLESH',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2021-DEVIL_IN_THE_FLESH.MP3'
  },
  {
    id: '13_10_2021-DEVIL_IN_THE_FLESH_REMIX.MP3-866',
    date: '13/10/2021',
    title: 'DEVIL IN THE FLESH REMIX',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2021-DEVIL_IN_THE_FLESH_REMIX.MP3'
  },
  {
    id: '13_10_2021-JUMPIN_JACK_FLASH.MP3-351',
    date: '13/10/2021',
    title: 'JUMPIN JACK FLASH',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2021-JUMPIN_JACK_FLASH.MP3'
  },
  {
    id: '13_10_2021-JUMPIN_JACK_FLASH_REMIX.MP3-235',
    date: '13/10/2021',
    title: 'JUMPIN JACK FLASH REMIX',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2021-JUMPIN_JACK_FLASH_REMIX.MP3'
  },
  {
    id: '06_10_2021-TURN_THE_PAGE.MP3-552',
    date: '06/10/2021',
    title: 'TURN THE PAGE',
    url: 'https://sessions.thetuco.fr/static/media/06_10_2021-TURN_THE_PAGE.MP3'
  },
  {
    id: '06_10_2021-TURN_THE_PAGE_REMIX.MP3-745',
    date: '06/10/2021',
    title: 'TURN THE PAGE REMIX',
    url: 'https://sessions.thetuco.fr/static/media/06_10_2021-TURN_THE_PAGE_REMIX.MP3'
  },
  {
    id: '22_09_2021-DEVIL_IN_THE_FLESH.MP3-49',
    date: '22/09/2021',
    title: 'DEVIL IN THE FLESH',
    url: 'https://sessions.thetuco.fr/static/media/22_09_2021-DEVIL_IN_THE_FLESH.MP3'
  },
  {
    id: '22_09_2021-TURN_THE_PAGE.MP3-797',
    date: '22/09/2021',
    title: 'TURN THE PAGE',
    url: 'https://sessions.thetuco.fr/static/media/22_09_2021-TURN_THE_PAGE.MP3'
  },
  {
    id: '22_09_2021-TURN_THE_PAGE_INSTRU.MP3-56',
    date: '22/09/2021',
    title: 'TURN THE PAGE INSTRU',
    url: 'https://sessions.thetuco.fr/static/media/22_09_2021-TURN_THE_PAGE_INSTRU.MP3'
  },
  {
    id: '13_10_2020-CEST_LE_LAPIN.MP3-735',
    date: '13/10/2020',
    title: 'CEST LE LAPIN',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2020-CEST_LE_LAPIN.MP3'
  },
  {
    id: '13_10_2020-IDEE1.MP3-473',
    date: '13/10/2020',
    title: 'IDEE1',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2020-IDEE1.MP3'
  },
  {
    id: '13_10_2020-IDEE2.MP3-746',
    date: '13/10/2020',
    title: 'IDEE2',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2020-IDEE2.MP3'
  },
  {
    id: '13_10_2020-JOURNEY_REMIX.MP3-473',
    date: '13/10/2020',
    title: 'JOURNEY REMIX',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2020-JOURNEY_REMIX.MP3'
  },
  {
    id: '13_10_2020-JOURNEY_V1.MP3-224',
    date: '13/10/2020',
    title: 'JOURNEY V1',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2020-JOURNEY_V1.MP3'
  },
  {
    id: '13_10_2020-JOURNEY_V2.MP3-593',
    date: '13/10/2020',
    title: 'JOURNEY V2',
    url: 'https://sessions.thetuco.fr/static/media/13_10_2020-JOURNEY_V2.MP3'
  },
  {
    id: '06_10_2020-WHAT_A_JOURNEY.MP3-807',
    date: '06/10/2020',
    title: 'WHAT A JOURNEY',
    url: 'https://sessions.thetuco.fr/static/media/06_10_2020-WHAT_A_JOURNEY.MP3'
  },
  {
    id: '06_10_2020-WHOLE_ LOTTA_LOVE.MP3-834',
    date: '06/10/2020',
    title: 'WHOLE  LOTTA LOVE',
    url: 'https://sessions.thetuco.fr/static/media/06_10_2020-WHOLE_ LOTTA_LOVE.MP3'
  },
  {
    id: '04_03_2020-ON_SE_RACCROCHE.MP3-919',
    date: '04/03/2020',
    title: 'ON SE RACCROCHE',
    url: 'https://sessions.thetuco.fr/static/media/04_03_2020-ON_SE_RACCROCHE.MP3'
  },
  {
    id: '04_03_2020-TURN_THE_PAGE.MP3-144',
    date: '04/03/2020',
    title: 'TURN THE PAGE',
    url: 'https://sessions.thetuco.fr/static/media/04_03_2020-TURN_THE_PAGE.MP3'
  },
  {
    id: '12_02_2020-IDEE_JP.MP3-623',
    date: '12/02/2020',
    title: 'IDEE JP',
    url: 'https://sessions.thetuco.fr/static/media/12_02_2020-IDEE_JP.MP3'
  },
  {
    id: '12_02_2020-TURN_THE_PAGE.MP3-132',
    date: '12/02/2020',
    title: 'TURN THE PAGE',
    url: 'https://sessions.thetuco.fr/static/media/12_02_2020-TURN_THE_PAGE.MP3'
  },
  {
    id: '29_01_2020-LOVERMAN.MP3-806',
    date: '29/01/2020',
    title: 'LOVERMAN',
    url: 'https://sessions.thetuco.fr/static/media/29_01_2020-LOVERMAN.MP3'
  },
  {
    id: '29_01_2020-NEW.MP3-292',
    date: '29/01/2020',
    title: 'NEW',
    url: 'https://sessions.thetuco.fr/static/media/29_01_2020-NEW.MP3'
  },
  {
    id: '29_01_2020-NEW_BIS.MP3-699',
    date: '29/01/2020',
    title: 'NEW BIS',
    url: 'https://sessions.thetuco.fr/static/media/29_01_2020-NEW_BIS.MP3'
  },
  {
    id: '21_01_2020-IDEE.MP3-709',
    date: '21/01/2020',
    title: 'IDEE',
    url: 'https://sessions.thetuco.fr/static/media/21_01_2020-IDEE.MP3'
  },
  {
    id: '21_01_2020-LOVERMAN.MP3-174',
    date: '21/01/2020',
    title: 'LOVERMAN',
    url: 'https://sessions.thetuco.fr/static/media/21_01_2020-LOVERMAN.MP3'
  },
  {
    id: '21_01_2020-Turn_The_Page.MP3-385',
    date: '21/01/2020',
    title: 'Turn The Page',
    url: 'https://sessions.thetuco.fr/static/media/21_01_2020-Turn_The_Page.MP3'
  },
  {
    id: '11_12_2019-ONE_OF_THESE_DAYS.MP3-693',
    date: '11/12/2019',
    title: 'ONE OF THESE DAYS',
    url: 'https://sessions.thetuco.fr/static/media/11_12_2019-ONE_OF_THESE_DAYS.MP3'
  },
  {
    id: '11_12_2019-SOLITUDE_N_SORROW.MP3-750',
    date: '11/12/2019',
    title: 'SOLITUDE N SORROW',
    url: 'https://sessions.thetuco.fr/static/media/11_12_2019-SOLITUDE_N_SORROW.MP3'
  },
  {
    id: '11_12_2019-TURN_THE_PAGE.MP3-903',
    date: '11/12/2019',
    title: 'TURN THE PAGE',
    url: 'https://sessions.thetuco.fr/static/media/11_12_2019-TURN_THE_PAGE.MP3'
  },
  {
    id: '04_12_2019-BREAK_ONE_OF_THESE_DAYS.MP3-220',
    date: '04/12/2019',
    title: 'BREAK ONE OF THESE DAYS',
    url: 'https://sessions.thetuco.fr/static/media/04_12_2019-BREAK_ONE_OF_THESE_DAYS.MP3'
  },
  {
    id: '04_12_2019-chaque_nuit.mp3-608',
    date: '04/12/2019',
    title: 'chaque nuit',
    url: 'https://sessions.thetuco.fr/static/media/04_12_2019-chaque_nuit.mp3'
  },
  {
    id: '04_12_2019-live_wire.mp3-984',
    date: '04/12/2019',
    title: 'live wire',
    url: 'https://sessions.thetuco.fr/static/media/04_12_2019-live_wire.mp3'
  },
  {
    id: '04_12_2019-love_is_not_enough.mp3-871',
    date: '04/12/2019',
    title: 'love is not enough',
    url: 'https://sessions.thetuco.fr/static/media/04_12_2019-love_is_not_enough.mp3'
  },
  {
    id: '04_12_2019-my_only_mistake.mp3-677',
    date: '04/12/2019',
    title: 'my only mistake',
    url: 'https://sessions.thetuco.fr/static/media/04_12_2019-my_only_mistake.mp3'
  },
  {
    id: '12_09_2019-NEW_ROCK.MP3-82',
    date: '12/09/2019',
    title: 'NEW ROCK',
    url: 'https://sessions.thetuco.fr/static/media/12_09_2019-NEW_ROCK.MP3'
  },
  {
    id: '12_09_2019-ON_SE_RACCROCHE.MP3-364',
    date: '12/09/2019',
    title: 'ON SE RACCROCHE',
    url: 'https://sessions.thetuco.fr/static/media/12_09_2019-ON_SE_RACCROCHE.MP3'
  },
  {
    id: '26_07_2019-DES_ENVIES_ALCOOL_LIVE.MP3-274',
    date: '26/07/2019',
    title: 'DES ENVIES ALCOOL LIVE',
    url: 'https://sessions.thetuco.fr/static/media/26_07_2019-DES_ENVIES_ALCOOL_LIVE.MP3'
  },
  {
    id: '11_07_2019-LE_REFLET.MP3-37',
    date: '11/07/2019',
    title: 'LE REFLET',
    url: 'https://sessions.thetuco.fr/static/media/11_07_2019-LE_REFLET.MP3'
  },
  {
    id: '11_07_2019-NEW_NEW_SONG_BIS_NEW_IDEE_BIS_3.MP3-330',
    date: '11/07/2019',
    title: 'NEW NEW SONG BIS NEW_IDEE_BIS_3',
    url: 'https://sessions.thetuco.fr/static/media/11_07_2019-NEW_NEW_SONG_BIS_NEW_IDEE_BIS_3.MP3'
  },
  {
    id: '11_07_2019-SOLITUDE_N_SORROW.MP3-290',
    date: '11/07/2019',
    title: 'SOLITUDE N SORROW',
    url: 'https://sessions.thetuco.fr/static/media/11_07_2019-SOLITUDE_N_SORROW.MP3'
  },
  {
    id: '16_04_2019-THUNDERSTRUCK.MP3-392',
    date: '16/04/2019',
    title: 'THUNDERSTRUCK',
    url: 'https://sessions.thetuco.fr/static/media/16_04_2019-THUNDERSTRUCK.MP3'
  },
  {
    id: '09_04_2019-LAQUELLE_DE_TOI.MP3-63',
    date: '09/04/2019',
    title: 'LAQUELLE DE TOI',
    url: 'https://sessions.thetuco.fr/static/media/09_04_2019-LAQUELLE_DE_TOI.MP3'
  },
  {
    id: '09_04_2019-THUNDERSTRUCK.MP3-233',
    date: '09/04/2019',
    title: 'THUNDERSTRUCK',
    url: 'https://sessions.thetuco.fr/static/media/09_04_2019-THUNDERSTRUCK.MP3'
  },
  {
    id: '02_04_2019-ELLE_LAVE_LES_VERRES.MP3-686',
    date: '02/04/2019',
    title: 'ELLE LAVE LES VERRES',
    url: 'https://sessions.thetuco.fr/static/media/02_04_2019-ELLE_LAVE_LES_VERRES.MP3'
  },
  {
    id: '02_04_2019-ONE_OF_THESE_DAYS.MP3-635',
    date: '02/04/2019',
    title: 'ONE OF THESE DAYS',
    url: 'https://sessions.thetuco.fr/static/media/02_04_2019-ONE_OF_THESE_DAYS.MP3'
  },
  {
    id: '02_04_2019-SPIT_OUT_THE_REST.MP3-754',
    date: '02/04/2019',
    title: 'SPIT OUT THE REST',
    url: 'https://sessions.thetuco.fr/static/media/02_04_2019-SPIT_OUT_THE_REST.MP3'
  },
  {
    id: '26_03_2019-ANDREAS_BLUES.MP3-584',
    date: '26/03/2019',
    title: 'ANDREAS BLUES',
    url: 'https://sessions.thetuco.fr/static/media/26_03_2019-ANDREAS_BLUES.MP3'
  },
  {
    id: '26_03_2019-HELLS_BELLS.MP3-483',
    date: '26/03/2019',
    title: 'HELLS BELLS',
    url: 'https://sessions.thetuco.fr/static/media/26_03_2019-HELLS_BELLS.MP3'
  },
  {
    id: '26_03_2019-TUESDAY_GONE.MP3-286',
    date: '26/03/2019',
    title: 'TUESDAY GONE',
    url: 'https://sessions.thetuco.fr/static/media/26_03_2019-TUESDAY_GONE.MP3'
  },
  {
    id: '19_03_2019-LES_VIEUX.MP3-172',
    date: '19/03/2019',
    title: 'LES VIEUX',
    url: 'https://sessions.thetuco.fr/static/media/19_03_2019-LES_VIEUX.MP3'
  },
  {
    id: '19_03_2019-LE_REFLET.MP3-708',
    date: '19/03/2019',
    title: 'LE REFLET',
    url: 'https://sessions.thetuco.fr/static/media/19_03_2019-LE_REFLET.MP3'
  },
  {
    id: '19_03_2019-SOLITUDE_SORROW.MP3-505',
    date: '19/03/2019',
    title: 'SOLITUDE SORROW',
    url: 'https://sessions.thetuco.fr/static/media/19_03_2019-SOLITUDE_SORROW.MP3'
  },
  {
    id: '19_03_2019-UNDER_COVER_OF_YOU.MP3-521',
    date: '19/03/2019',
    title: 'UNDER COVER OF YOU',
    url: 'https://sessions.thetuco.fr/static/media/19_03_2019-UNDER_COVER_OF_YOU.MP3'
  },
  {
    id: '12_03_2019-ANTISOCIAL.MP3-660',
    date: '12/03/2019',
    title: 'ANTISOCIAL',
    url: 'https://sessions.thetuco.fr/static/media/12_03_2019-ANTISOCIAL.MP3'
  },
  {
    id: '12_03_2019-HELLS_BELLS.MP3-858',
    date: '12/03/2019',
    title: 'HELLS BELLS',
    url: 'https://sessions.thetuco.fr/static/media/12_03_2019-HELLS_BELLS.MP3'
  },
  {
    id: '12_03_2019-MAYBE_TOMORROW.MP3-128',
    date: '12/03/2019',
    title: 'MAYBE TOMORROW',
    url: 'https://sessions.thetuco.fr/static/media/12_03_2019-MAYBE_TOMORROW.MP3'
  },
  {
    id: '12_03_2019-PRISONNER.MP3-762',
    date: '12/03/2019',
    title: 'PRISONNER',
    url: 'https://sessions.thetuco.fr/static/media/12_03_2019-PRISONNER.MP3'
  },
  {
    id: '12_03_2019-ROSIE.MP3-657',
    date: '12/03/2019',
    title: 'ROSIE',
    url: 'https://sessions.thetuco.fr/static/media/12_03_2019-ROSIE.MP3'
  },
  {
    id: '05_03_2019-LAQUELLE_DE_TOI.MP3-113',
    date: '05/03/2019',
    title: 'LAQUELLE DE TOI',
    url: 'https://sessions.thetuco.fr/static/media/05_03_2019-LAQUELLE_DE_TOI.MP3'
  },
  {
    id: '05_03_2019-LAQUELLE_DE_TOI_REMIX.MP3-363',
    date: '05/03/2019',
    title: 'LAQUELLE DE TOI REMIX',
    url: 'https://sessions.thetuco.fr/static/media/05_03_2019-LAQUELLE_DE_TOI_REMIX.MP3'
  },
  {
    id: '05_03_2019-NEW_LINE.MP3-789',
    date: '05/03/2019',
    title: 'NEW LINE',
    url: 'https://sessions.thetuco.fr/static/media/05_03_2019-NEW_LINE.MP3'
  },
  {
    id: '05_03_2019-NEW_LINE_REMIX.MP3-24',
    date: '05/03/2019',
    title: 'NEW LINE REMIX',
    url: 'https://sessions.thetuco.fr/static/media/05_03_2019-NEW_LINE_REMIX.MP3'
  },
  {
    id: '05_03_2019-SIMPLE_MAN.MP3-475',
    date: '05/03/2019',
    title: 'SIMPLE MAN',
    url: 'https://sessions.thetuco.fr/static/media/05_03_2019-SIMPLE_MAN.MP3'
  },
  {
    id: '05_03_2019-SIMPLE_MAN_REMIX.MP3-600',
    date: '05/03/2019',
    title: 'SIMPLE MAN REMIX',
    url: 'https://sessions.thetuco.fr/static/media/05_03_2019-SIMPLE_MAN_REMIX.MP3'
  },
  {
    id: '26_02_2019-DRESS.MP3-466',
    date: '26/02/2019',
    title: 'DRESS',
    url: 'https://sessions.thetuco.fr/static/media/26_02_2019-DRESS.MP3'
  },
  {
    id: '26_02_2019-FLESH.MP3-806',
    date: '26/02/2019',
    title: 'FLESH',
    url: 'https://sessions.thetuco.fr/static/media/26_02_2019-FLESH.MP3'
  },
  {
    id: '26_02_2019-LIVE_WIRE.MP3-928',
    date: '26/02/2019',
    title: 'LIVE WIRE',
    url: 'https://sessions.thetuco.fr/static/media/26_02_2019-LIVE_WIRE.MP3'
  },
  {
    id: '26_02_2019-SHOOK_ME.MP3-599',
    date: '26/02/2019',
    title: 'SHOOK ME',
    url: 'https://sessions.thetuco.fr/static/media/26_02_2019-SHOOK_ME.MP3'
  },
  {
    id: '26_02_2019-SHOT_DOWN.MP3-510',
    date: '26/02/2019',
    title: 'SHOT DOWN',
    url: 'https://sessions.thetuco.fr/static/media/26_02_2019-SHOT_DOWN.MP3'
  },
  {
    id: '08_02_2019-JAZZ.MP3.MP3-96',
    date: '08/02/2019',
    title: 'JAZZ',
    url: 'https://sessions.thetuco.fr/static/media/08_02_2019-JAZZ.MP3.MP3'
  },
  {
    id: '08_02_2019-JH.MP3-232',
    date: '08/02/2019',
    title: 'JH',
    url: 'https://sessions.thetuco.fr/static/media/08_02_2019-JH.MP3'
  },
  {
    id: '08_02_2019-NEW_DOBRO_BIS.MP3-654',
    date: '08/02/2019',
    title: 'NEW DOBRO BIS',
    url: 'https://sessions.thetuco.fr/static/media/08_02_2019-NEW_DOBRO_BIS.MP3'
  },
  {
    id: '08_02_2019-NEW_NEW_DOBRO.MP3-310',
    date: '08/02/2019',
    title: 'NEW NEW DOBRO',
    url: 'https://sessions.thetuco.fr/static/media/08_02_2019-NEW_NEW_DOBRO.MP3'
  },
  {
    id: '08_02_2019-NEW_NEW_NEW_BLUES.MP3-223',
    date: '08/02/2019',
    title: 'NEW NEW NEW BLUES',
    url: 'https://sessions.thetuco.fr/static/media/08_02_2019-NEW_NEW_NEW_BLUES.MP3'
  },
  {
    id: '29_01_2019-DRESS.MP3-533',
    date: '29/01/2019',
    title: 'DRESS',
    url: 'https://sessions.thetuco.fr/static/media/29_01_2019-DRESS.MP3'
  },
  {
    id: '29_01_2019-MA_PEAU.MP3-824',
    date: '29/01/2019',
    title: 'MA PEAU',
    url: 'https://sessions.thetuco.fr/static/media/29_01_2019-MA_PEAU.MP3'
  },
  {
    id: '29_01_2019-SO_NICE.MP3-320',
    date: '29/01/2019',
    title: 'SO NICE',
    url: 'https://sessions.thetuco.fr/static/media/29_01_2019-SO_NICE.MP3'
  },
  {
    id: '25_01_2019-ANCIENNE_DOBRO.MP3-117',
    date: '25/01/2019',
    title: 'ANCIENNE DOBRO',
    url: 'https://sessions.thetuco.fr/static/media/25_01_2019-ANCIENNE_DOBRO.MP3'
  },
  {
    id: '25_01_2019-ANDREAS_BLUES.MP3-32',
    date: '25/01/2019',
    title: 'ANDREAS BLUES',
    url: 'https://sessions.thetuco.fr/static/media/25_01_2019-ANDREAS_BLUES.MP3'
  },
  {
    id: '25_01_2019-COUNTRY.MP3-852',
    date: '25/01/2019',
    title: 'COUNTRY',
    url: 'https://sessions.thetuco.fr/static/media/25_01_2019-COUNTRY.MP3'
  },
  {
    id: '25_01_2019-DOBRO.MP3-780',
    date: '25/01/2019',
    title: 'DOBRO',
    url: 'https://sessions.thetuco.fr/static/media/25_01_2019-DOBRO.MP3'
  },
  {
    id: '25_01_2019-JAZZ.MP3-377',
    date: '25/01/2019',
    title: 'JAZZ',
    url: 'https://sessions.thetuco.fr/static/media/25_01_2019-JAZZ.MP3'
  },
  {
    id: '25_01_2019-TAS_EU_MA_PEAU.MP3-277',
    date: '25/01/2019',
    title: 'TAS EU MA PEAU',
    url: 'https://sessions.thetuco.fr/static/media/25_01_2019-TAS_EU_MA_PEAU.MP3'
  }
]
;
