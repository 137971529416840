import { useState, useEffect } from "react";
import "./styles/styles.css";
import {songs} from './datas/Songs'
import Search from './Search'
import SongList from "./SongList";
import Player from "./Player";
import dotenv from  'dotenv'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'

export default function App() {

  const [targetClickedUrl, setTargetClickedUrl] = useState()
  const [targetClickedName, setTargetClickedName] = useState()

  const [songsItems, setSongsItems] = useState({songs})

  const [playing, setPlaying] = useState(false)

  const getLocalStorageTitle = localStorage.getItem("lastSongTitle");
  const [currentSong, setSong] =  useState(
   getLocalStorageTitle ? JSON.parse(getLocalStorageTitle) : ""
  );

  const getLocalStorageUrl = localStorage.getItem("lastSongUrl");
  const [currentUrl, setUrl] = useState(
   getLocalStorageUrl ? JSON.parse(getLocalStorageUrl) : ""
  );

  useEffect(() => {
   localStorage.setItem("lastSongUrl", JSON.stringify(currentUrl));
  // console.log('lastSongUrl cookie : ' + localStorage.getItem('lastSongUrl'))
  }, [currentUrl]);

  useEffect(() => {
   localStorage.setItem("lastSongTitle", JSON.stringify(currentSong));
  // console.log('lastSongTitle cookie : ' + localStorage.getItem('lastSongTitle'))
  }, [currentSong]);

  const [currentPass, setCurrentPass] = useState('')

  function checkUser(e) {
    e.preventDefault();
    if (e.target.value === process.env.REACT_APP_PASSWORD) {
      setCurrentPass(e.target.value)
      document.cookie = "login=allow; max-age=10800" // add cookie for 3 hours
    }
  }

  function logout(e) {
    e.preventDefault()
    swal("Déconnexion ?", {
      buttons: ["Annuler", true]
    })
      .then((value) => {
        if (value === true) {
          setCurrentPass('') // remove pass
          document.cookie = "login="; // remove login cookie
          setTimeout(function () {
            window.location.href = '/'
          }, 100);
        };
      })
  }

  function showPass() {
      let inputPass = document.getElementById('password');
      inputPass.type = 'text'
      setTimeout(function () {
        inputPass.type = 'password'
        inputPass.focus()
      }, 1500);
  }

  if (currentPass === process.env.REACT_APP_PASSWORD || document.cookie.includes('login=allow')) {
    return (
      <div className="App">
        <h1>Tuco sessions</h1>
        <button className='loginBtn'
                onClick={logout}>Se déconnecter
              <FontAwesomeIcon icon={faSignOutAlt} style={{marginLeft: 5}} />
        </button>
        <Search />
        <SongList
          songsItems={songsItems}
          setSongsItems={setSongsItems}
          currentSong={currentSong}
          setSong={setSong}
          currentUrl={currentUrl}
          setUrl={setUrl}
          targetClickedUrl={targetClickedUrl}
          setTargetClickedUrl={setTargetClickedUrl}
          targetClickedName={targetClickedName}
          setTargetClickedName={setTargetClickedName}
          playing={playing}
          setPlaying={setPlaying}
        />
        <Player
          songsItems={songsItems}
          setSongsItems={setSongsItems}
          currentSong={currentSong}
          setSong={setSong}
          currentUrl={currentUrl}
          setUrl={setUrl}
          targetClickedUrl={targetClickedUrl}
          setTargetClickedUrl={setTargetClickedUrl}
          targetClickedName={targetClickedName}
          setTargetClickedName={setTargetClickedName}
          playing={playing}
          setPlaying={setPlaying}
        />
      </div>
    );
  } else {
    return (
      <div className="App">
        <h1>Tuco sessions</h1>
        <h2>Authentification</h2>
        <form style={{maxWidth: 'max-content', margin: 'auto'}}>
          <FontAwesomeIcon icon={faUnlockAlt} color='grey' style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 8}}/>
          <input type='password'
                 id='password'
                 style={{fontStyle: 'normal', paddingLeft: 28}}
                 onChange={checkUser}
                 autoFocus
                 autoComplete='off'>
          </input>
          <FontAwesomeIcon onClick={showPass} icon={faEye} color='grey' style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 8, cursor: 'pointer'}}/>
        </form>
      </div>
    )
  }
}
